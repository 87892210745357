import { default as companies8aIOoMb6MoMeta } from "/app/pages/home/companies.vue?macro=true";
import { default as infowFx7RlSvlCMeta } from "/app/pages/home/company/info.vue?macro=true";
import { default as pendentxP3dsFBxmkMeta } from "/app/pages/home/company/pendent.vue?macro=true";
import { default as servicesdpgV7Nni0VMeta } from "/app/pages/home/company/services.vue?macro=true";
import { default as index4aGTGBC5tJMeta } from "/app/pages/home/index.vue?macro=true";
import { default as homeH3NsyxtJwrMeta } from "/app/pages/home.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as login_45supplierAkSIHHCSrYMeta } from "/app/pages/login-supplier.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91token_93FaRHggxOrgMeta } from "/app/pages/recovery/[token].vue?macro=true";
import { default as indexf2rSPuLNHmMeta } from "/app/pages/recovery/index.vue?macro=true";
import { default as _91token_93TCnHO7b2ucMeta } from "/app/pages/register/[token].vue?macro=true";
import { default as indexhOBcdtdQ4pMeta } from "/app/pages/register/index.vue?macro=true";
import { default as stepsTjtnS2esmeMeta } from "/app/pages/register/steps.vue?macro=true";
export default [
  {
    name: homeH3NsyxtJwrMeta?.name ?? undefined,
    path: homeH3NsyxtJwrMeta?.path ?? "/home",
    meta: homeH3NsyxtJwrMeta || {},
    alias: homeH3NsyxtJwrMeta?.alias || [],
    redirect: homeH3NsyxtJwrMeta?.redirect,
    component: () => import("/app/pages/home.vue").then(m => m.default || m),
    children: [
  {
    name: companies8aIOoMb6MoMeta?.name ?? "home-companies",
    path: companies8aIOoMb6MoMeta?.path ?? "companies",
    meta: companies8aIOoMb6MoMeta || {},
    alias: companies8aIOoMb6MoMeta?.alias || [],
    redirect: companies8aIOoMb6MoMeta?.redirect,
    component: () => import("/app/pages/home/companies.vue").then(m => m.default || m)
  },
  {
    name: infowFx7RlSvlCMeta?.name ?? "home-company-info",
    path: infowFx7RlSvlCMeta?.path ?? "company/info",
    meta: infowFx7RlSvlCMeta || {},
    alias: infowFx7RlSvlCMeta?.alias || [],
    redirect: infowFx7RlSvlCMeta?.redirect,
    component: () => import("/app/pages/home/company/info.vue").then(m => m.default || m)
  },
  {
    name: pendentxP3dsFBxmkMeta?.name ?? "home-company-pendent",
    path: pendentxP3dsFBxmkMeta?.path ?? "company/pendent",
    meta: pendentxP3dsFBxmkMeta || {},
    alias: pendentxP3dsFBxmkMeta?.alias || [],
    redirect: pendentxP3dsFBxmkMeta?.redirect,
    component: () => import("/app/pages/home/company/pendent.vue").then(m => m.default || m)
  },
  {
    name: servicesdpgV7Nni0VMeta?.name ?? "home-company-services",
    path: servicesdpgV7Nni0VMeta?.path ?? "company/services",
    meta: servicesdpgV7Nni0VMeta || {},
    alias: servicesdpgV7Nni0VMeta?.alias || [],
    redirect: servicesdpgV7Nni0VMeta?.redirect,
    component: () => import("/app/pages/home/company/services.vue").then(m => m.default || m)
  },
  {
    name: index4aGTGBC5tJMeta?.name ?? "home",
    path: index4aGTGBC5tJMeta?.path ?? "",
    meta: index4aGTGBC5tJMeta || {},
    alias: index4aGTGBC5tJMeta?.alias || [],
    redirect: index4aGTGBC5tJMeta?.redirect,
    component: () => import("/app/pages/home/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login_45supplierAkSIHHCSrYMeta?.name ?? "login-supplier",
    path: login_45supplierAkSIHHCSrYMeta?.path ?? "/login-supplier",
    meta: login_45supplierAkSIHHCSrYMeta || {},
    alias: login_45supplierAkSIHHCSrYMeta?.alias || [],
    redirect: login_45supplierAkSIHHCSrYMeta?.redirect,
    component: () => import("/app/pages/login-supplier.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93FaRHggxOrgMeta?.name ?? "recovery-token",
    path: _91token_93FaRHggxOrgMeta?.path ?? "/recovery/:token()",
    meta: _91token_93FaRHggxOrgMeta || {},
    alias: _91token_93FaRHggxOrgMeta?.alias || [],
    redirect: _91token_93FaRHggxOrgMeta?.redirect,
    component: () => import("/app/pages/recovery/[token].vue").then(m => m.default || m)
  },
  {
    name: indexf2rSPuLNHmMeta?.name ?? "recovery",
    path: indexf2rSPuLNHmMeta?.path ?? "/recovery",
    meta: indexf2rSPuLNHmMeta || {},
    alias: indexf2rSPuLNHmMeta?.alias || [],
    redirect: indexf2rSPuLNHmMeta?.redirect,
    component: () => import("/app/pages/recovery/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93TCnHO7b2ucMeta?.name ?? "register-token",
    path: _91token_93TCnHO7b2ucMeta?.path ?? "/register/:token()",
    meta: _91token_93TCnHO7b2ucMeta || {},
    alias: _91token_93TCnHO7b2ucMeta?.alias || [],
    redirect: _91token_93TCnHO7b2ucMeta?.redirect,
    component: () => import("/app/pages/register/[token].vue").then(m => m.default || m)
  },
  {
    name: indexhOBcdtdQ4pMeta?.name ?? "register",
    path: indexhOBcdtdQ4pMeta?.path ?? "/register",
    meta: indexhOBcdtdQ4pMeta || {},
    alias: indexhOBcdtdQ4pMeta?.alias || [],
    redirect: indexhOBcdtdQ4pMeta?.redirect,
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: stepsTjtnS2esmeMeta?.name ?? "register-steps",
    path: stepsTjtnS2esmeMeta?.path ?? "/register/steps",
    meta: stepsTjtnS2esmeMeta || {},
    alias: stepsTjtnS2esmeMeta?.alias || [],
    redirect: stepsTjtnS2esmeMeta?.redirect,
    component: () => import("/app/pages/register/steps.vue").then(m => m.default || m)
  }
]