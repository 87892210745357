import revive_payload_client_15drkElIXg from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.2.4_@unocss+reset@0.59.4_axios@1.4.0_eslint@8.41.0_floating-vue@5._ilrzprycdi33lgvqv64su5jo2i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3AXm09FRPb from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.2.4_@unocss+reset@0.59.4_axios@1.4.0_eslint@8.41.0_floating-vue@5._ilrzprycdi33lgvqv64su5jo2i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Q1kZ7AAERj from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.2.4_@unocss+reset@0.59.4_axios@1.4.0_eslint@8.41.0_floating-vue@5._ilrzprycdi33lgvqv64su5jo2i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZmKtcSI3Ul from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.2.4_@unocss+reset@0.59.4_axios@1.4.0_eslint@8.41.0_floating-vue@5._ilrzprycdi33lgvqv64su5jo2i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_GuiLSZoldV from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.2.4_@unocss+reset@0.59.4_axios@1.4.0_eslint@8.41.0_floating-vue@5._ilrzprycdi33lgvqv64su5jo2i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_tjTR5ZhnMu from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@4.9.5_vue@3.4.25/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_4LNMGmqD9D from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.2.4_@unocss+reset@0.59.4_axios@1.4.0_eslint@8.41.0_floating-vue@5._ilrzprycdi33lgvqv64su5jo2i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_khJ3Kzbicv from "/app/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.2.4_@unocss+reset@0.59.4_axios@1.4.0_eslint@8.41.0_floating-vue@5._ilrzprycdi33lgvqv64su5jo2i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import shared_state_client_ByfWq2IAz2 from "/app/plugins/shared-state.client.ts";
import userContext_ak4XObrGNe from "/app/plugins/userContext.ts";
export default [
  revive_payload_client_15drkElIXg,
  unhead_3AXm09FRPb,
  router_Q1kZ7AAERj,
  payload_client_ZmKtcSI3Ul,
  check_outdated_build_client_GuiLSZoldV,
  plugin_vue3_tjTR5ZhnMu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4LNMGmqD9D,
  chunk_reload_client_khJ3Kzbicv,
  shared_state_client_ByfWq2IAz2,
  userContext_ak4XObrGNe
]